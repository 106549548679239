import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;


  .myDataGrid {
    height: 100%;
    min-height: 300px;
    max-height: 320px;
  }

  .loadingContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const BtnGroupContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 0;
  margin-top: 0.5rem;
  color: #706f6f;
  font-family: 'Jost', sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  .btn-group {
    width: 100%;
    cursor: pointer;
    border-radius: 0px;
    background-color: #ecedf0;
    color: rgb(37, 56, 88);
    border: none;
    font-size: 0.9rem;
    &:active {
      outline: none;
      box-shadow: none !important;
    }
    &:focus {
      outline: none;
      box-shadow: none !important;
    }
    &:hover {
      background-color: #8850bf;
      color: #fff !important;
    }
  }

  .btn-group-active {
    background-color: #57069e;
    color: #fff !important;

    &:hover {
      background-color: #57069e;
      color: #fff;
    }
  }
`;
