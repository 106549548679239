import { Card } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
`;

export const StyledCard = styled(Card)`
  text-align: left;
  padding: 6px 12px 6px 12px;
  transition: transform 0.3s ease !important;


  &:hover {
    transform: scale(1.05);
  }
  .valueTitle {
    font-size: 2.5rem;
  }
  .subTitle {
    margin-top: -10px;
    font-size: 1.2rem;
  }


`;