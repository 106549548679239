import React, { useEffect, useState } from 'react';
import { Container, ContainerButtons } from './styles';
import FormDefault from '~/components/FormDefault';

import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import Loja from '~/components/Loja';
import { useRelatorioRaioXVendas } from './RelatorioRaioXVendasContext';
import { InputSelect } from '~/components/NovosInputs';
import AbaGeral from './Abas/AbaGeral';
import AbaFinalizadora from './Abas/AbaFinalizadora';
import AbaDepartamento from './Abas/AbaDepartamento';
import AbaTemporal from './Abas/AbaTemporal';
import AbaCaixa from './Abas/AbaCaixa';
import InputDateRange from '~/components/Inputs/InputDateRange';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { IoIosSearch } from 'react-icons/io';
import { FaEraser } from 'react-icons/fa';
import { MdOutlineCancel } from 'react-icons/md';

const RelatorioRaioXVendasContent: React.FC = () => {
  const {
    formRelatorioRaioXVendas: {
      watch,
      reset,
      control,
      register,
      setValue,
      setError,
      getValues,
      setFocus,
      formState: { errors },
      clearErrors,
      handleSubmit,
      trigger,
    },
    keyRef,
    setDtaFiltro,
    dtaFiltro,
    handleSearch,
    handleResetForm,
    handleCancelForm,
    departamentos,
    setResetLoja,
    resetLoja,
    exib,
    loading,
    disabled,
    tab,
    handleChangeTab,
  } = useRelatorioRaioXVendas();

  const handleDataExecutar = (ini: string, fim: string, isInvalid: boolean) => {
    setDtaFiltro({
      ...dtaFiltro,
      dta_filtro_ini: {
        ...dtaFiltro.dta_filtro_ini,
        value: ini,
        isInvalid,
      },
      dta_filtro_fim: {
        ...dtaFiltro.dta_filtro_fim,
        value: fim,
        isInvalid,
      },
    });
  };

  return (
    <Container>
      <FormDefault
        codTela={308}
        title="Relatório Raio-X de Vendas"
        onSave={() => Promise.resolve()}
        onCancel={() => []}
        onClearFields={() => []}
        onNew={() => []}
        onDelete={() => []}
        onReturnSearch={() => []}
        isDelete={false}
        isClear={false}
        isSave
        isNew={false}
        isCancel={false}
      >
        <Row>
          <Col sm={12} md={12}>
            <Loja
              isMulti
              onChange={(val) => {
                setResetLoja(false);
                setValue('lojas', val);
              }}
              IsInvalid={false}
              resetLojas={resetLoja}
              disabled={disabled}
            />
          </Col>
          <Col sm={12} md={6}>
            <InputSelect
              label="Departamento"
              placeholder="Selecione o Departamento"
              name="cod_departamento"
              register={register}
              isError={!!errors.cod_departamento}
              control={control}
              options={departamentos}
              disabled={disabled}
              changeSelected={(selected: any) => {
                setValue('cod_departamento', selected);
              }}
            />
          </Col>
          <Col sm={12} md={6}>
            <InputDateRange
              dataIni={dtaFiltro.dta_filtro_ini.value}
              dataFim={dtaFiltro.dta_filtro_fim.value}
              labelText="Intervalo"
              key={keyRef.current}
              isRequired
              setInvalid={false}
              isDisabled={disabled}
              onChange={(ini: string, fim: string, isInvalid = true) => {
                handleDataExecutar(ini, fim, isInvalid);
                setValue('dta_inicio', ini);
                setValue('dta_fim', fim);
              }}
            />
          </Col>
          <Col className="mt-2">
            <ContainerButtons>
              <CustomButtonNovo
                onClick={() => handleSearch()}
                label="Pesquisar"
                icon={IoIosSearch}
                disabled={disabled}
                showLoading={loading}
              />
              <CustomButtonNovo
                disabled={!disabled}
                onClick={handleCancelForm}
                label="Cancelar"
                icon={MdOutlineCancel}
                variant="cancel"
              />
              <CustomButtonNovo
                onClick={handleResetForm}
                disabled={disabled}
                label="Limpar"
                icon={FaEraser}
                variant="clear"
              />
            </ContainerButtons>
          </Col>
          {exib && (
            <Col sm={12} md={12} className="mt-2">
              <Tabs
                id="controlled-produto"
                activeKey={tab}
                onSelect={(k) => handleChangeTab(k)}
              >
                <Tab eventKey="geral" title="Resumo Geral">
                  <AbaGeral />
                </Tab>
                <Tab eventKey="departamento" title="Departamento">
                  <AbaDepartamento />
                </Tab>
                <Tab eventKey="finalizadora" title="Finalizadora">
                  <AbaFinalizadora />
                </Tab>
                <Tab eventKey="temporal" title="Temporal">
                  <AbaTemporal />
                </Tab>
                <Tab eventKey="caixa" title="Caixa">
                  <AbaCaixa />
                </Tab>
              </Tabs>
            </Col>
          )}
        </Row>
      </FormDefault>
    </Container>
  );
};

export default RelatorioRaioXVendasContent;
