import React, { useEffect, useState } from 'react';
import { Container, StyledCard } from './styles';
import { CardContent, Grid, Typography } from '@material-ui/core';
import { Col, Row } from 'react-bootstrap';
import CustomCard from '../../components/CustomCard';
import { useRelatorioRaioXVendas } from '../../RelatorioRaioXVendasContext';
import { moneyFormat } from '~/utils/functions';

export interface IGeneral {
  val_custo_estoque: string | null;
  val_faturamento_view: string | null;
  val_ticket_medio: string | null;
  qtd_cupom: string | null;
  val_lucro_bruto_view: string | null;
  per_margem_lucro: string | null;
  qtd_ean: string | null;
  val_fator_rentabilidade_view: string | null;
  des_fator_rentabilidade: string | null;
  fator_rentabilidade: string | null;
}

const AbaGeral: React.FC = () => {
  const { generalInfos, loading } = useRelatorioRaioXVendas();
  const [generalObj, setGeneralObj] = useState<IGeneral>({
    val_custo_estoque: null,
    val_faturamento_view: null,
    val_ticket_medio: null,
    qtd_cupom: null,
    val_lucro_bruto_view: null,
    per_margem_lucro: null,
    qtd_ean: null,
    val_fator_rentabilidade_view: null,
    des_fator_rentabilidade: null,
    fator_rentabilidade: null,
  });

  const formatGeneralObj = (general: IGeneral) => {
    return {
      val_custo_estoque: `R$ ${moneyFormat(
        String(general?.val_custo_estoque ?? 0),
      )}`,
      val_faturamento_view: `R$ ${general?.val_faturamento_view ?? '0,00'}`,
      val_ticket_medio: `R$ ${general?.val_ticket_medio ?? '0,00'}`,
      qtd_cupom: general?.qtd_cupom
        ? Number(general?.qtd_cupom).toLocaleString('pt-BR')
        : '0',
      val_lucro_bruto_view: `R$ ${general?.val_lucro_bruto_view ?? '0,00'}`,
      per_margem_lucro: `${general?.per_margem_lucro ?? '0,00'} %`,
      qtd_ean: general?.qtd_ean
        ? Number(general?.qtd_ean).toLocaleString('pt-BR')
        : '0',
      val_fator_rentabilidade_view: general?.val_fator_rentabilidade_view ?? '',
      des_fator_rentabilidade: general?.des_fator_rentabilidade ?? '',
      fator_rentabilidade: `${general?.val_fator_rentabilidade_view ?? '0'} ${
        general?.des_fator_rentabilidade ?? ''
      }`,
    };
  };

  useEffect(() => {
    const newGeneral = formatGeneralObj(generalInfos);
    setGeneralObj(newGeneral);
  }, [generalInfos]);

  return (
    <Container>
      <Row>
        <Col sm={12} md={3}>
          <CustomCard
            loading={loading}
            value={generalObj.val_custo_estoque ?? 'R$ 0,00'}
            label="Custo do Estoque"
            color="#E57373"
          />
        </Col>
        <Col sm={12} md={3}>
          <CustomCard
            loading={loading}
            value={generalObj.val_faturamento_view ?? 'R$ 0,00'}
            label="Faturamento"
            color="#81C784"
          />
        </Col>
        <Col sm={12} md={3}>
          <CustomCard
            loading={loading}
            value={generalObj.val_ticket_medio || 'R$ 0,00'}
            label="Ticket Médio"
            color="#64B5F6"
          />
        </Col>
        <Col sm={12} md={3}>
          <CustomCard
            loading={loading}
            value={generalObj.qtd_cupom || '0'}
            label="Qtd. Cupons"
            color="#FFB74D"
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={12} md={3}>
          <CustomCard
            loading={loading}
            value={generalObj.val_lucro_bruto_view || 'R$ 0,00'}
            label="Lucro Bruto"
            color="#4CAF50"
          />
        </Col>
        <Col sm={12} md={3}>
          <CustomCard
            loading={loading}
            value={generalObj.per_margem_lucro || '0%'}
            label="Margem de Lucro"
            color="#8e7cc3"
          />
        </Col>
        <Col sm={12} md={3}>
          <CustomCard
            loading={loading}
            value={generalObj.qtd_ean || '0'}
            label="Qtd. EAN's"
            color="#f6b26b"
          />
        </Col>
        <Col sm={12} md={3}>
          <CustomCard
            loading={loading}
            value={generalObj.fator_rentabilidade || '0'}
            label="Fator Rentabilidade"
            color="#ff9900"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AbaGeral;
