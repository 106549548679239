import React, { useCallback, useEffect, useRef } from 'react';

import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { ColumnSeparator, TableEmpty } from './styles';

export interface CustomGridColumns {
  field: string;
  headerName: string;
  width: number;
  format?: string;
  options?: any[];
  minWidth?: number;
  align?: string;
  fixed?: boolean;
  hide?: boolean;
  sortable?: boolean;
  disableColumnMenu?: boolean;
  renderCell?: (row: any) => void;
  fixedDirection?: string;
}

interface FormProps {
  rows: any[];
  columns: CustomGridColumns[];
  loading: boolean;
  showToolTip?: boolean;
  emptyLineMessage?: string;
  isEmptyLineMessage?: boolean;
}

/**
 * Linhas e colunas para exemplo
 */

/**
 * @param rows Array de objetos contendo as linhas da tabela (as keys dos objetos devem ser iguais as ids das colunas)
 * @param columns Array de objetos contendo as colunas
 * @param columns Para fixar uma coluna basta passar uma chave chamada fixed com o valor true e Left com 0 (também é necessário passar um width e um minWidth)
 * @param columns Caso mais de uma coluna seja fixa, a coluna seguinte deverá receber o valor de Left igual ao minWidth da coluna anterior
 * @returns Componente DataGrid personalizado
 */

const CustomDataGrid: React.FC<FormProps> = (props) => {
  const {
    rows,
    columns,
    loading = false,
    showToolTip = true,
    emptyLineMessage = '',
    isEmptyLineMessage = false,
  } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const tableRef = useRef<any>();

  useEffect(() => {
    if (rows.length === 0) {
      setPage(0);
    }
  }, [rows]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    tableRef.current.scrollTop = 0;
  };

  function formatType(
    item: any,
    type: string | undefined,
    options: any | undefined,
  ) {
    if (type === 'money') {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(item);
    }
    if (type === 'dta') {
      return new Date(item).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    }
    if (type === 'dtatime') {
      return new Date(item).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    }
    if (type === 'number') {
      return new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
      }).format(item);
    }
    if (type === 'number2') {
      return new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(item);
    }
    if (type === 'number6') {
      return new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 6,
      }).format(item);
    }
    if (type === 'Percentage') {
      return `${new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
      }).format(item)}%`;
    }
    if (options !== undefined) {
      const Item = options.find(
        (i: { value: number; label: string }) => i?.value === item,
      );
      if (Item) {
        return Item.label;
      }
    }
    return item;
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const LinearProgressBar: React.FC = () => {
    return (
      <LinearProgress
        style={{
          left: '0',
          right: '0',
          background: '#c2a0f8',
          color: '#8850BF',
          position: 'sticky',
        }}
      />
    );
  };
  const visibleColumns = columns.filter((column) => !column.hide);

  return (
    <>
      <Paper
        style={{
          width: '100%',
          minHeight: '430px',

          flex: 1,
          zIndex: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TableContainer
          style={{
            flex: 1,
            maxHeight: '430px',
            position: 'relative',
          }}
          ref={tableRef}
          className="tableDevolucaoNFeContainer"
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            id="tableDevolucaoESaida"
          >
            <TableHead style={{ position: 'sticky', top: '0', zIndex: '1' }}>
              <TableRow>
                {visibleColumns.map((column, index) => {
                  const isLast = index === visibleColumns.length - 1;
                  if (column.fixed)
                    return (
                      <TableCell
                        key={column.field}
                        className="text-truncate"
                        style={{
                          position: 'sticky',
                          right: 0,
                          top: 0,
                          width: column.width,
                          minWidth: column.width,
                          background: '#000',
                          zIndex: 2,
                          textAlign: 'center',
                        }}
                      >
                        {column.headerName}
                      </TableCell>
                    );
                  return (
                    <TableCell
                      key={column.field}
                      className="text-truncate"
                      style={{
                        minWidth: column.minWidth
                          ? column.minWidth
                          : column.width,
                        position: 'relative',
                        zIndex: 0,
                      }}
                    >
                      {column.headerName}
                      {!isLast && (
                        <ColumnSeparator>
                          <svg
                            className="MuiSvgIcon-root MuiDataGrid-iconSeparator"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M11 19V5h2v14z" />
                          </svg>
                        </ColumnSeparator>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row, rowIndex) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    // eslint-disable-next-line react/no-array-index-key
                    key={rowIndex}
                  >
                    {visibleColumns.map((column) => {
                      if (column.fixed)
                        return (
                          <TableCell
                            key={column.field}
                            style={{
                              position: 'sticky',
                              right: 0,
                              width: column.width,
                              minWidth: column.width,
                              background: '#ffffff',
                              zIndex: 1,
                            }}
                          >
                            {column.renderCell
                              ? column.renderCell(row)
                              : formatType(
                                  row[column.field],
                                  column?.format,
                                  column?.options,
                                )}
                          </TableCell>
                        );
                      if (showToolTip) {
                        return (
                          <TableCell key={column.field}>
                            {column.renderCell
                              ? column.renderCell(row)
                              : formatType(
                                  row[column.field],
                                  column?.format,
                                  column?.options,
                                )}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={column.field}>
                          {column.renderCell
                            ? column.renderCell(row)
                            : formatType(
                                row[column.field],
                                column?.format,
                                column?.options,
                              )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {isEmptyLineMessage && rows.length === 0 && (
            <TableEmpty>{emptyLineMessage}</TableEmpty>
          )}
        </TableContainer>
        {loading && <LinearProgressBar />}
        <TableFooter
          style={{ padding: '1.3rem', borderTop: '1px solid #00000021' }}
        >
          {`Total de registros: ${rows.length}`}
        </TableFooter>
      </Paper>
    </>
  );
};

export default CustomDataGrid;
