import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;


  .myDataGrid {
    height: 100%;
    min-height: 300px;
    max-height: 320px;
  }
`;


