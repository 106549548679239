import { Card } from "@material-ui/core";
import styled from "styled-components";

export const StyledCard = styled(Card)`
  text-align: left;
  padding: 6px 12px 6px 12px;
  transition: transform 0.3s ease !important;


  &:hover {
    transform: scale(1.05);
  }
  .valueTitle {
    font-size: 2rem;
  }
  .subTitle {
    margin-top: -10px;
    font-size: 1.2rem;
  }


`;