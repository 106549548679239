import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  FormDataSelect,
  IDepartamento,
  RelatorioRaioXVendasContextData,
  RelatorioRaioXVendasProviderProps,
  Select,
} from './types';
import { useForm } from 'react-hook-form';
import { LojaContext } from '~/context/loja';
import api from '~/services/api';
import moment from 'moment';
import { format } from 'date-fns';
import { set } from 'lodash';
import { ukUAGrid } from '@material-ui/data-grid';
import { nanoid } from 'nanoid';
import { useQueryClient } from 'react-query';

export const RelatorioRaioXVendasContext = createContext(
  {} as RelatorioRaioXVendasContextData,
);

export const RelatorioRaioXVendasProvider: React.FC<
  RelatorioRaioXVendasProviderProps
> = ({ children }) => {
  const { loja } = useContext(LojaContext);
  const queryClient = useQueryClient();
  const keyRef = useRef<any>(Date.now());
  const [resetLoja, setResetLoja] = useState(false);
  const [exib, setExib] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [departamentos, setDepartamentos] = useState<Select[]>([]);
  const [tab, setTab] = useState('geral');
  const [dtaFiltro, setDtaFiltro] = useState<FormDataSelect>({
    dta_filtro_ini: {
      value: moment(),
      isInvalid: false,
      isRequired: true,
    },
    dta_filtro_fim: {
      value: moment().add(1, 'days'),
      isInvalid: false,
      isRequired: true,
    },
  });

  // Estados ABA GERAL
  const [generalInfos, setGeneralInfos] = useState(null);

  // Estados ABA DEPARTAMENTO
  const [departmentInfos, setDepartmentInfos] = useState([]);
  const [departmentChartInfos, setDepartmentChartInfos] = useState<any[]>([]);
  const [tabDepartment, setTabDepartment] = useState<Select>({
    value: 0,
    label: 'seção',
  });

  // ESTADOS ABA FINALIZADORA
  const [finalizadoraInfos, setFinalizadoraInfos] = useState([]);
  const [finalizadoraChartInfos, setFinalizadoraChartInfos] = useState<any[]>(
    [],
  );

  // ESTADOS ABA TEMPORAL
  const [temporalInfos, setTemporalInfos] = useState([]);
  const [temporalChartInfos, setTemporalChartInfos] = useState([]);
  const [tabTemporal, setTabTemporal] = useState<Select>({
    value: 0,
    label: 'periodo',
  });

  // ESTADOS ABA CAIXA
  const [caixaInfos, setCaixaInfos] = useState([]);
  const [caixaChartInfos, setCaixaChartInfos] = useState<any[]>([]);

  /**
   * Form Relatorio RaioX Vendas
   */
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    setFocus,
    clearErrors,
    formState,
    watch,
    reset,
    trigger,
  } = useForm({
    // resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  async function getDepartamentos() {
    const res = await api.get('/produto/departamentos');
    const { data, success } = res.data;
    if (success) {
      const select = data.map((item: IDepartamento) => {
        return {
          value: item.cod_departamento,
          label: `${item.cod_departamento} - ${item.descricao}`,
          cod_tipo_item: item.cod_tipo_item,
          des_tipo_item: item.des_tipo_item,
          cod_id_ctb: item.cod_id_ctb,
        };
      });

      setDepartamentos(select);
    }
  }

  // Consultas abas
  async function getGeneral(payload: any) {
    setLoading(true);

    const cacheGeneralInfos: any =
      queryClient.getQueryData(['cache-raiox', `cacheGeneralInfos`]) ||
      undefined;
    if (cacheGeneralInfos) {
      setGeneralInfos(cacheGeneralInfos);
      setLoading(false);
      return;
    }

    const res = await api.get('/relatorio-raiox-vendas/general', {
      params: payload,
    });
    const { data, success } = res.data;
    if (success) {
      queryClient.setQueryData(['cache-raiox', `cacheGeneralInfos`], data[0]);
      setGeneralInfos(data[0]);
    }

    setLoading(false);
  }
  async function getDepartment(payload: any) {
    setLoading(true);

    const cacheDepartmentInfos: any =
      queryClient.getQueryData([
        'cache-raiox',
        `cacheDepartmentInfos-${payload.sectionDepartment}`,
      ]) || undefined;
    const cacheDepartmentChartInfos: any =
      queryClient.getQueryData([
        'cache-raiox',
        `cacheDepartmentChartInfos-${payload.sectionDepartment}`,
      ]) || undefined;
    if (cacheDepartmentInfos && cacheDepartmentChartInfos) {
      setDepartmentInfos(cacheDepartmentInfos);
      setDepartmentChartInfos(cacheDepartmentChartInfos);
      setLoading(false);
      return;
    }

    const res = await api.get('/relatorio-raiox-vendas/department', {
      params: payload,
    });
    const { data, success } = res.data;
    if (success) {
      const formatedDepartmentInfos = data.map((departamento: any) => {
        const uuid = nanoid();
        departamento.uuid = uuid;
        departamento.id = uuid;
        return departamento;
      });
      const formatedDepartmentChartInfos = handleChartFormat(
        formatedDepartmentInfos,
      );
      queryClient.setQueryData(
        ['cache-raiox', `cacheDepartmentInfos-${payload.sectionDepartment}`],
        formatedDepartmentInfos,
      );
      queryClient.setQueryData(
        [
          'cache-raiox',
          `cacheDepartmentChartInfos-${payload.sectionDepartment}`,
        ],
        formatedDepartmentChartInfos,
      );
      setDepartmentChartInfos(formatedDepartmentChartInfos);
      setDepartmentInfos(formatedDepartmentInfos);
    }
    setLoading(false);
  }
  async function getFinalizadora(payload: any) {
    setLoading(true);

    const cacheFinalizadoraInfos: any =
      queryClient.getQueryData(['cache-raiox', `cacheFinalizadoraInfos`]) ||
      undefined;
    const cacheFinalizadoraChartInfos: any =
      queryClient.getQueryData([
        'cache-raiox',
        `cacheFinalizadoraChartInfos`,
      ]) || undefined;
    if (cacheFinalizadoraInfos && cacheFinalizadoraChartInfos) {
      setFinalizadoraInfos(cacheFinalizadoraInfos);
      setFinalizadoraChartInfos(cacheFinalizadoraChartInfos);
      setLoading(false);
      return;
    }

    const res = await api.get('/relatorio-raiox-vendas/finalizadora', {
      params: payload,
    });
    const { data, success } = res.data;
    if (success) {
      const formatedFinalizadoraInfos = data.map((finalizadora: any) => {
        const uuid = nanoid();
        finalizadora.uuid = uuid;
        finalizadora.id = uuid;
        return finalizadora;
      });
      const formatedFinalizadoraChartInfos = handleChartFormat(
        formatedFinalizadoraInfos,
      );

      queryClient.setQueryData(
        ['cache-raiox', `cacheFinalizadoraInfos`],
        formatedFinalizadoraInfos,
      );
      queryClient.setQueryData(
        ['cache-raiox', `cacheFinalizadoraChartInfos`],
        formatedFinalizadoraChartInfos,
      );

      setFinalizadoraChartInfos(formatedFinalizadoraChartInfos);
      setFinalizadoraInfos(formatedFinalizadoraInfos);
    }
    setLoading(false);
  }
  async function getCaixa(payload: any) {
    setLoading(true);

    const cacheCaixaInfos: any =
      queryClient.getQueryData(['cache-raiox', `cacheCaixaInfos`]) || undefined;
    const cacheCaixaChartInfos: any =
      queryClient.getQueryData(['cache-raiox', `cacheCaixaChartInfos`]) ||
      undefined;
    if (cacheCaixaInfos && cacheCaixaChartInfos) {
      setCaixaInfos(cacheCaixaInfos);
      setCaixaChartInfos(cacheCaixaChartInfos);
      setLoading(false);
      return;
    }

    const res = await api.get('/home-dashboard/caixas-hoje', {
      params: payload,
    });
    const { data, success } = res.data;
    if (success) {
      const formatedCaixaInfos = data.caixasHoje.data.map((caixa: any) => {
        const uuid = nanoid();
        caixa.uuid = uuid;
        caixa.id = uuid;
        return caixa;
      });
      const formatedCaixaChartInfos = handleChartFormat(formatedCaixaInfos);

      queryClient.setQueryData(
        ['cache-raiox', `cacheCaixaInfos`],
        formatedCaixaInfos,
      );
      queryClient.setQueryData(
        ['cache-raiox', `cacheCaixaChartInfos`],
        formatedCaixaChartInfos,
      );

      setCaixaChartInfos(formatedCaixaChartInfos);
      setCaixaInfos(formatedCaixaInfos);
    }
    setLoading(false);
  }
  async function getTemporal(payload: any) {
    const cacheTemporalChartInfos: any =
      queryClient.getQueryData([
        'cache-raiox',
        `cacheTemporalChartInfos-${payload.sectionTemporal}`,
      ]) || undefined;
    if (cacheTemporalChartInfos) {
      setTemporalChartInfos(cacheTemporalChartInfos);
      return;
    }

    const res = await api.get('/relatorio-raiox-vendas/temporal', {
      params: payload,
    });
    const { data, success } = res.data;
    if (success) {
      const formatedTemporalInfos = data.map((temporal: any) => {
        const uuid = nanoid();
        temporal.uuid = uuid;
        temporal.id = uuid;
        temporal.Valor = temporal.val_total;
        temporal.name = temporal.dta_cupom;
        if (payload.sectionTemporal === 'semana') {
          temporal.name = temporal.nome_dia;
        } else if (payload.sectionTemporal === 'mes') {
          temporal.name = temporal.dia_mes;
        } else if (payload.sectionTemporal === 'horario') {
          temporal.name = temporal.hora_cupom;
        }
        return temporal;
      });
      queryClient.setQueryData(
        ['cache-raiox', `cacheTemporalChartInfos-${payload.sectionTemporal}`],
        formatedTemporalInfos,
      );
      setTemporalChartInfos(formatedTemporalInfos);
    }
  }
  async function getTemporalCards(payload: any) {
    setLoading(true);

    const cacheTemporalInfos: any =
      queryClient.getQueryData(['cache-raiox', `cacheTemporalInfos`]) ||
      undefined;
    if (cacheTemporalInfos) {
      setTemporalInfos(cacheTemporalInfos);
      setLoading(false);
      return;
    }

    const res = await api.get('/relatorio-raiox-vendas/temporal-cards', {
      params: payload,
    });
    const { data, success } = res.data;
    if (success) {
      queryClient.setQueryData(['cache-raiox', `cacheTemporalInfos`], data[0]);
      setTemporalInfos(data[0]);
    }
    setLoading(false);
  }

  useEffect(() => {
    getDepartamentos();
  }, []);

  // Método de busca ao mudar de aba
  const handleChangeTab = (tabName: any) => {
    setTab(tabName);
    if (disabled) handleSearch(tabName);
  };

  // Método de busca ao mudar de aba [DEPARTAMENTO]
  const handleChangeTabDepartment = async (sectionDepartment: any) => {
    const dta_ini = dtaFiltro.dta_filtro_ini.value.format('YYYY-MM-DD');
    const dta_fim = dtaFiltro.dta_filtro_fim.value.format('YYYY-MM-DD');
    const { cod_departamento, lojas } = getValues();

    const payload = {
      cod_departamento: cod_departamento.value,
      lojas,
      dta_ini,
      dta_fim,
      sectionDepartment,
    };

    try {
      if (disabled) await getDepartment(payload);
    } catch (error) {
      console.log(error);
    }
  };

  // Método de busca ao mudar de aba [TEMPORAL]
  const handleChangeTabTemporal = async (sectionTemporal: any) => {
    const dta_ini = dtaFiltro.dta_filtro_ini.value.format('YYYY-MM-DD');
    const dta_fim = dtaFiltro.dta_filtro_fim.value.format('YYYY-MM-DD');
    const { cod_departamento, lojas } = getValues();

    const payload = {
      cod_departamento: cod_departamento.value,
      lojas,
      dta_ini,
      dta_fim,
      sectionTemporal,
    };

    try {
      if (disabled) await getTemporal(payload);
    } catch (error) {
      console.log(error);
    }
  };

  // Método limpar campos de busca
  const handleResetForm = () => {
    reset();
    setDisabled(false);
    setResetLoja(true);
    setDtaFiltro({
      dta_filtro_ini: {
        value: moment(),
        isInvalid: false,
        isRequired: true,
      },
      dta_filtro_fim: {
        value: moment().add(1, 'days'),
        isInvalid: false,
        isRequired: true,
      },
    });
    setTabDepartment({ value: 0, label: 'seção' });
    setTabTemporal({ value: 0, label: 'periodo' });
    setValue('cod_departamento', '');
    setGeneralInfos(null);
    setDepartmentInfos([]);
    setFinalizadoraInfos([]);
    setTemporalInfos([]);
    setCaixaInfos([]);

    setDepartmentChartInfos([]);
    setFinalizadoraChartInfos([]);
    setTemporalChartInfos([]);
    setCaixaChartInfos([]);
    keyRef.current = moment();
    queryClient.removeQueries({ queryKey: ['cache-raiox'], exact: false });

    setTimeout(() => {
      setResetLoja(false);
    }, 10);
  };
  const handleCancelForm = () => {
    setExib(false);
    setTab('geral');
    setDisabled(false);
    queryClient.removeQueries({ queryKey: ['cache-raiox'], exact: false });
  };
  // Método de busca
  const handleSearch = async (tabName: any = null) => {
    setLoading(true);
    const dta_ini = dtaFiltro.dta_filtro_ini.value.format('YYYY-MM-DD');
    const dta_fim = dtaFiltro.dta_filtro_fim.value.format('YYYY-MM-DD');
    const { cod_departamento, lojas } = getValues();

    const payload = {
      cod_departamento: cod_departamento.value,
      lojas,
      dta_ini,
      dta_fim,
      sectionDepartment: tabDepartment.label,
      sectionTemporal: tabTemporal.label,
    };

    const selectedTab = tabName || tab;
    try {
      if (selectedTab === 'geral') {
        await getGeneral(payload);
      } else if (selectedTab === 'departamento') {
        await getDepartment(payload);
      } else if (selectedTab === 'finalizadora') {
        await getFinalizadora(payload);
      } else if (selectedTab === 'caixa') {
        await getCaixa(payload);
      } else if (selectedTab === 'temporal') {
        await getTemporalCards(payload);
        await getTemporal(payload);
      }

      setDisabled(true);
      setExib(true);
      setLoading(false);
    } catch (error) {
      setExib(false);
      setLoading(false);
      console.log(error);
    }
  };

  // Limitador de informações para os gráficos
  const handleChartFormat = (data: any, limit = 5) => {
    const dadosOrdenados = [...data].sort((a, b) => b.val_total - a.val_total);
    const principais = dadosOrdenados.slice(0, limit);
    if (dadosOrdenados.length > limit) {
      const outros = dadosOrdenados.slice(limit).reduce(
        (acc, item) => {
          acc.val_total += item.val_total;
          return acc;
        },
        {
          des_departamento: 'OUTROS',
          val_total: 0,
        },
      );
      outros.val_total = Number(outros.val_total.toFixed(2));
      return [...principais, outros];
    }
    return principais;
  };

  const valuesProvider = {
    loja,
    departamentos,
    generalInfos,
    departmentInfos,
    departmentChartInfos,
    finalizadoraInfos,
    finalizadoraChartInfos,
    caixaInfos,
    caixaChartInfos,
    temporalInfos,
    temporalChartInfos,
    formRelatorioRaioXVendas: {
      watch,
      reset,
      control,
      register,
      setValue,
      setError,
      getValues,
      setFocus,
      formState,
      clearErrors,
      handleSubmit,
      trigger,
    },
    handleSearch,
    handleResetForm,
    handleCancelForm,
    handleChangeTab,
    handleChangeTabDepartment,
    handleChangeTabTemporal,

    keyRef,
    dtaFiltro,
    setDtaFiltro,
    resetLoja,
    setResetLoja,
    exib,
    setExib,
    loading,
    setLoading,
    disabled,
    setDisabled,
    tab,
    setTab,
    tabDepartment,
    setTabDepartment,
    tabTemporal,
    setTabTemporal,
  };
  return (
    <RelatorioRaioXVendasContext.Provider value={valuesProvider}>
      {children}
    </RelatorioRaioXVendasContext.Provider>
  );
};

export const useRelatorioRaioXVendas = (): RelatorioRaioXVendasContextData => {
  return useContext(RelatorioRaioXVendasContext);
};
