import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
`;


export const ContainerButtons = styled.div`
  display: flex;
  height: 42px;
  justify-content: flex-end;
  gap: 10px;
  button {
    display: flex;
    gap: 10px;
    min-width: 100px;
    height: 42px;
    align-items: center;
    justify-content: center;
  }
  button: {
    box-shadow: none;
  }

  button.success {
    background-color: #28a745 !important;
    &:disabled {
      background-color: #b7e5c1;
      cursor: not-allowed;
      opacity: 0.7;
      &:hover {
        background-color: #b7e5c1;
        opacity: 1;
      }
    }
  }
`;