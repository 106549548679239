import React from 'react';
import RelatorioRaioXVendasContent from './RelatorioRaioXVendasContent';
import { RelatorioRaioXVendasProvider } from './RelatorioRaioXVendasContext';

const RelatorioRaioXVendas: React.FC = () => {
  function defaultOnlyReport() {
    return false;
  }
  return (
    <RelatorioRaioXVendasProvider>
      <RelatorioRaioXVendasContent />
    </RelatorioRaioXVendasProvider>
  );
};

export default RelatorioRaioXVendas;
