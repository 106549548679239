import React, { useState } from 'react';
import { BtnGroupContainer, Container } from './styles';
import { Col, Row } from 'react-bootstrap';
import ButtonAtlaskit from '@atlaskit/button';
import { Select } from '../../types';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { useRelatorioRaioXVendas } from '../../RelatorioRaioXVendasContext';
import { moneyFormat } from '~/utils/functions';
import CustomDataGrid from '../../components/CustomDataGrid';
import { CircularProgress } from '@material-ui/core';
import { InputRadioBox } from '~/components/NovosInputs';

const colors = [
  '#FF6361',
  '#0088FE',
  '#FF8042',
  '#FFA600',
  '#00C49F',
  '#FFB5B8',
  '#A28BFE',
  '#FFD700',
];

const AbaDepartamento: React.FC = () => {
  const {
    departmentInfos,
    departmentChartInfos,
    loading,
    handleChangeTabDepartment,
    tabDepartment,
    setTabDepartment,
  } = useRelatorioRaioXVendas();

  const columnsDepartment = [
    {
      field: 'id',
      headerName: 'id',
      hide: true,
      width: 100,
    },
    {
      field: 'cod_loja',
      headerName: 'Loja',
      type: 'string',
      width: 70,
    },
    {
      field: 'des_departamento',
      headerName: 'Departamento',
      type: 'number',
      width: 70,
    },
    {
      field: 'val_total',
      headerName: 'Val. Venda',
      type: 'number',
      width: 120,
      renderCell: (row: any) => {
        return `R$ ${moneyFormat(row.val_total || 0)}`;
      },
    },
    {
      field: 'percentual_participacao',
      headerName: 'Participação',
      type: 'string',
      width: 70,
      renderCell: (row: any) => {
        const percentualParticipacao = row.percentual_participacao.trim();
        return `${percentualParticipacao || 0}%`;
      },
    },
  ];

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  return (
    <Container>
      <Row>
        <Col sm={12} md={6}>
          <InputRadioBox
            name="departamento"
            qtdButtons={3}
            defaultValue={0}
            options={[
              {
                value: 0,
                label: 'seção',
                id: 'Secao',
              },
              {
                value: 1,
                label: 'Grupo',
                id: 'Grupo',
              },
              {
                value: 2,
                label: 'Subgrupo',
                id: 'Subgrupo',
              },
            ]}
            onChange={(value: any) => {
              const items = ['seção', 'grupo', 'subgrupo'];
              setTabDepartment({ value, label: items[value] });
              handleChangeTabDepartment(items[value]);
            }}
          />
          <ResponsiveContainer width="100%" height={400}>
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <CircularProgress size={35} style={{ color: '#8850BF' }} />
              </div>
            ) : departmentChartInfos.length === 0 ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <p style={{ textAlign: 'center', fontSize: '18px' }}>
                  Nenhum registro encontrado.
                </p>
              </div>
            ) : (
              <PieChart>
                <Pie
                  data={departmentChartInfos || []}
                  dataKey="val_total"
                  nameKey="des_departamento"
                  cx="50%"
                  cy="50%"
                  outerRadius={120}
                  fill="#8884d8"
                  label={({ value }) => formatCurrency(value)}
                >
                  {departmentChartInfos.map((entry: any, index: any) => (
                    <Cell
                      key={`cell-${entry.des_departamento}`}
                      fill={colors[index]}
                    />
                  ))}
                </Pie>
                <Tooltip formatter={(value: number) => formatCurrency(value)} />
                <Legend />
              </PieChart>
            )}
          </ResponsiveContainer>
        </Col>
        <Col sm={12} md={6}>
          <CustomDataGrid
            loading={loading}
            rows={departmentInfos}
            columns={columnsDepartment}
            isEmptyLineMessage
            emptyLineMessage="Nenhum registro encontrado."
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AbaDepartamento;
