import React from 'react';
import { StyledCard } from './styles';
import { CircularProgress } from '@material-ui/core';

interface CustomCardProps {
  value: string;
  label: string;
  color: string;
  loading?: boolean;
}

const CustomCard: React.FC<CustomCardProps> = ({
  value,
  label,
  color,
  loading,
}) => {
  return (
    <StyledCard style={{ backgroundColor: color, color: '#fff' }}>
      <div className="valueTitle">
        {loading ? (
          <CircularProgress size={15} style={{ color: '#ffffff' }} />
        ) : (
          value
        )}
      </div>
      <div className="subTitle">{label}</div>
    </StyledCard>
  );
};

export default CustomCard;
