import React, { useEffect, useState } from 'react';
import { BtnGroupContainer, Container } from './styles';
import { Col, Row } from 'react-bootstrap';
import CustomCard from '../../components/CustomCard';
import ButtonAtlaskit from '@atlaskit/button';
import { Select } from '../../types';
import {
  CartesianGrid,
  Customized,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Text,
} from 'recharts';
import { useRelatorioRaioXVendas } from '../../RelatorioRaioXVendasContext';
import { moneyFormat } from '~/utils/functions';
import { format } from 'date-fns';
import { InputRadioBox } from '~/components/NovosInputs';

export interface ITemporal {
  dia_record_vendas: string;
  faturamento_dia_record: string;
  melhor_dia_semana: string;
  melhor_faixa_horario: string;
}
const AbaTemporal: React.FC = () => {
  const {
    loading,
    temporalInfos,
    temporalChartInfos,
    tabTemporal,
    setTabTemporal,
    handleChangeTabTemporal,
  } = useRelatorioRaioXVendas();
  const [temporalObj, setTemporalObj] = useState<ITemporal>({
    dia_record_vendas: '',
    faturamento_dia_record: 'R$ 0,00',
    melhor_dia_semana: '',
    melhor_faixa_horario: '',
  });

  const formatTemporalObj = (temporal: ITemporal) => {
    return {
      dia_record_vendas: temporal?.dia_record_vendas
        ? format(new Date(temporal.dia_record_vendas), 'dd/MM/yyyy')
        : '-',
      faturamento_dia_record: `R$ ${moneyFormat(
        String(temporal.faturamento_dia_record ?? 0),
      )}`,
      melhor_dia_semana: temporal.melhor_dia_semana ?? '-',
      melhor_faixa_horario: temporal.melhor_faixa_horario ?? '-',
    };
  };

  useEffect(() => {
    const newTemporal = formatTemporalObj(temporalInfos);
    setTemporalObj(newTemporal);
  }, [temporalInfos]);

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const formatCurrencyLegend = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  return (
    <Container>
      <Row>
        <Col sm={12} md={3}>
          <CustomCard
            loading={loading}
            value={temporalObj.melhor_dia_semana ?? '-'}
            label="Melhor dia da Semana"
            color="#f6b26b"
          />
        </Col>
        <Col sm={12} md={3}>
          <CustomCard
            loading={loading}
            value={temporalObj.melhor_faixa_horario ?? '-'}
            label="Melhor Faixa do Período"
            color="#6fa8dc"
          />
        </Col>
        <Col sm={12} md={3}>
          <CustomCard
            loading={loading}
            value={temporalObj.dia_record_vendas ?? '-'}
            label="Dia Recorde"
            color="#c27ba0"
          />
        </Col>
        <Col sm={12} md={3}>
          <CustomCard
            loading={loading}
            value={temporalObj.faturamento_dia_record ?? 'R$ 0,00'}
            label="Melhor Faturamento do Dia"
            color="#93c47d"
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm={12} md={8}>
          <InputRadioBox
            name="temporal"
            qtdButtons={4}
            defaultValue={0}
            options={[
              {
                value: 0,
                label: 'Período Completo',
                id: 'periodo',
              },
              {
                value: 1,
                label: 'Dia da Semana',
                id: 'semana',
              },
              {
                value: 2,
                label: 'Dia do Mês',
                id: 'mes',
              },
              { value: 3, label: 'Faixa de Horário', id: 'horario' },
            ]}
            onChange={(value: any) => {
              const items = ['periodo', 'semana', 'mes', 'horario'];
              setTabTemporal({ value, label: items[value] });
              handleChangeTabTemporal(items[value]);
            }}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={12} md={12}>
          <ResponsiveContainer width="100%" height={405}>
            <LineChart data={temporalChartInfos}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis
                tickFormatter={(value: number) => formatCurrencyLegend(value)}
              />
              <Tooltip formatter={(value: number) => formatCurrency(value)} />
              <Legend />
              <Customized
                component={() => {
                  return temporalChartInfos.length === 0 ? (
                    <Text
                      style={{ transform: `translate(50%, 50%)` }}
                      x={0}
                      y={-20}
                      textAnchor="middle"
                      verticalAnchor="middle"
                    >
                      Nenhum registro encontrado.
                    </Text>
                  ) : null;
                }}
              />
              <Line type="monotone" dataKey="Valor" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default AbaTemporal;
