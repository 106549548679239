import React from 'react';
import { Container } from './styles';
import { Col, Row } from 'react-bootstrap';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { moneyFormat } from '~/utils/functions';
import { useRelatorioRaioXVendas } from '../../RelatorioRaioXVendasContext';
import { CircularProgress } from '@material-ui/core';
import CustomDataGrid from '../../components/CustomDataGrid';

const colors = [
  '#FF6361',
  '#0088FE',
  '#FF8042',
  '#FFA600',
  '#00C49F',
  '#FFB5B8',
  '#A28BFE',
  '#FFD700',
];

const AbaFinalizadora: React.FC = () => {
  const { finalizadoraInfos, finalizadoraChartInfos, loading } =
    useRelatorioRaioXVendas();

  const columnsFinalizadora = [
    {
      field: 'id',
      headerName: 'id',
      hide: true,
      width: 100,
    },
    {
      field: 'cod_loja',
      headerName: 'Loja',
      type: 'string',
      width: 70,
    },
    {
      field: 'des_finalizadora',
      headerName: 'Finalizadora',
      type: 'string',
      width: 70,
    },
    {
      field: 'val_total',
      headerName: 'Val. Venda',
      type: 'number',
      width: 120,
      renderCell: (row: any) => {
        return `R$ ${moneyFormat(row.val_total || 0)}`;
      },
    },
    {
      field: 'percentual_participacao',
      headerName: 'Participação',
      type: 'string',
      width: 70,
      renderCell: (row: any) => {
        const percentualParticipacao = row.percentual_participacao.trim();
        return `${percentualParticipacao || 0}%`;
      },
    },
  ];

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  return (
    <Container>
      <Row>
        <Col sm={12} md={6}>
          <ResponsiveContainer width="100%" height={350}>
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <CircularProgress size={35} style={{ color: '#8850BF' }} />
              </div>
            ) : finalizadoraChartInfos.length === 0 ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <p style={{ textAlign: 'center', fontSize: '18px' }}>
                  Nenhum registro encontrado.
                </p>
              </div>
            ) : (
              <PieChart>
                <Pie
                  data={finalizadoraChartInfos || []}
                  dataKey="val_total"
                  nameKey="des_finalizadora"
                  cx="50%"
                  cy="50%"
                  outerRadius={70}
                  fill="#8884d8"
                  label={({ value }) => formatCurrency(value)}
                >
                  {finalizadoraChartInfos.map((entry: any, index: any) => (
                    <Cell
                      key={`cell-${entry.des_finalizadora}`}
                      fill={colors[index]}
                    />
                  ))}
                </Pie>
                <Tooltip formatter={(value: number) => formatCurrency(value)} />
                <Legend />
              </PieChart>
            )}
          </ResponsiveContainer>
        </Col>
        <Col sm={12} md={6}>
          <CustomDataGrid
            loading={loading}
            rows={finalizadoraInfos}
            columns={columnsFinalizadora}
            isEmptyLineMessage
            emptyLineMessage="Nenhum registro encontrado."
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AbaFinalizadora;
